<style scoped>
</style>
<template>
  <div class="vue-box">
    <div class="c-panel">
      <!-- 参数栏 -->
      <div class="c-title">直付通商户列表</div>
      <el-form :inline="true" size="mini">
        <el-form-item label="直付通服务商:" placeholder="请选择">
          <el-select v-model="p.mchName" @click.native="getZft()">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in zftlist" :key="item.appid" :label="item.mchName" :value="item.mchName" />
          </el-select>
        </el-form-item>
        <el-form-item label="二级商户号:">
          <el-input v-model="p.smid" placeholder="直付通SMID" clearable></el-input>
        </el-form-item>
        <el-form-item v-if="this.sa_admin.role == 2" label="码队选择:">
          <el-select size="mini" v-model="p.mchId" @click.native="getMchCode" @change="f5" v-el-select-loadmore="loadmore">
            <el-option label="全部" :value="null"></el-option>
            <el-option v-for="item in selectList" :key="item.mchId" :label="item.merchantNickName" :value="item.mchId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="this.sa_admin.role == 3" icon="el-icon-plus" @click="add()">添加商户</el-button>
          <el-button type="primary" icon="el-icon-search" @click="
              p.page = 1;
              f5();
            ">查询</el-button>
          <el-button type="success" icon="el-icon-refresh" @click="
              p.page = 1;
              f5();
            ">刷新</el-button>
          <el-button type="primary" v-if="this.sa_admin.role == 2" icon="el-icon-paperclip" @click="showUserInfoPage()">切换码队</el-button>
        </el-form-item>
      </el-form>
      <el-table :row-class-name="tableRowClassName" :data="dataList" :key="conditionsKey" @selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }" size="mini">
        <el-table-column type="selection" width="70px"></el-table-column>
        <el-table-column label="编号" prop="id" width="50px"> </el-table-column>
        <el-table-column label="商户名" prop="mchName" min-width="180px"></el-table-column>
        <el-table-column label="二级商户号" prop="subMerchantId" width="130px"></el-table-column>
        <el-table-column label="直付通服务商" prop="configMchName" width="150px">
        </el-table-column>
        <el-table-column label="创建时间" prop="createdTime" width="120px">
        </el-table-column>
        <el-table-column label="状态" width="120px">
          <template slot-scope="s">
            <el-switch :value="s.row.status" :active-value="1" :inactive-value="0" inactive-color="#ff4949" @change="setStatus(s.row)"></el-switch>
            <b style="color: green; margin-left: 10px" v-if="s.row.status == 1">启用</b>
            <b style="color: red; margin-left: 10px" v-if="s.row.status == 0">禁用</b>
          </template>
        </el-table-column>
        <el-table-column label="昨日收入" width="120px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.zrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="yesterDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日收入" width="120px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrsr
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshFee(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="今日成功率" width="100px">
          <template slot-scope="s">
            <el-link style="color: green; margin-right: 5px; font-size: 14px">{{
              s.row.jrcgl
            }}</el-link>
            <el-link icon="el-icon-refresh" style="font-size: 16px" @click="toDayRefreshRate(s.row)"></el-link>
          </template>
        </el-table-column>
        <el-table-column label="限额" width="100px">
          <template slot-scope="s">
            <b>￥{{ s.row.limits }}</b>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="备注" prop="remarks" min-width="150px" :formatter="remarksFormat">
        </el-table-column>
        <!-- <el-table-column v-if="this.sa_admin.role == 2" label="特殊操作">
          <template slot-scope="s">
            <el-button class="c-btn" type="primary" icon="el-icon-paperclip" @click="showUserInfoPageB(s.row)">转移</el-button>
          </template>
        </el-table-column> -->
        <el-table-column prop="address" label="操作" width="150px" fixed="right">
          <template slot-scope="s">
            <el-button class="c-btn" type="success" icon="el-icon-edit" @click="update(s.row)">编辑</el-button>
            <el-dropdown style="margin-left: 5px;">
              <span class="el-dropdown-link">
                <el-button class="c-btn" type="primary" icon="el-icon-more">更多</el-button>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item icon="el-icon-link" @click.native="yc(s.row,1)">预充链接</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="yc(s.row,0)">养号链接</el-dropdown-item> -->
                <!-- <el-dropdown-item icon="el-icon-link" @click.native="mh(s.row,0)">提取链接</el-dropdown-item> -->
                <el-dropdown-item icon="el-icon-link" @click.native="zftWapPay(s.row,0)">手机网站提取链接</el-dropdown-item>
                <el-dropdown-item icon="el-icon-link" @click.native="zftPcPay(s.row,0)">电脑网站提取链接</el-dropdown-item>
                <el-dropdown-item icon="el-icon-delete" @click.native="del(s.row)">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-box">
        <el-pagination background layout="total, prev, pager, next, sizes" :current-page.sync="p.page" :page-size.sync="p.limit" :total="dataCount" :page-sizes="[1, 10, 20, 30, 40, 50, 100]" @current-change="f5(true)" @size-change="f5(true)">
        </el-pagination>
      </div>
    </div>
    <AddCodeMch ref="add-alipay-code-update"></AddCodeMch>
    <el-dialog :title="title" :visible.sync="userDialogVisible" v-if="userDialogVisible" width="330px" append-to-body>
      <!--  传递给子组件的值     -->
      <SwitchCode @mchId="getSwitchMchId"></SwitchCode>
      <div slot="footer" class="dialog-footer">
        <el-button @click="switchMch()">切 换</el-button>
        <el-button @click="cancel()">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SwitchCode from "../user/switch-code-mch.vue";
import AddCodeMch from "./add-alipay-code-mch.vue";
export default {
  components: { SwitchCode, AddCodeMch },
  data() {
    return {
      p: {
        // 查询参数
        mchName: null,
        mchId: null,
        smid: null,
        type: 2,
        page: 1,
        limit: 10,
      },
      title: "切换隶属码队",
      userDialogVisible: false,
      dataCount: 1422,
      dataList: [],
      selectList: [],
      conditionsKey: 0,
      drawer: false,
      direction: 'rtl',
      zftlist: [],
      multipleSelection: [],
    };
  },
  methods: {
    // 数据刷新
    f5: function () {
      if (this.sa_admin.role == 2) {
        var defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v1/alipay/mch/queryAllAlipaySubMerchant",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      } else {
        this.p.mchId = this.sa_admin.mchId;
        defaultCfg = {
          isBody: true, //是否是请求体请求
        };
        this.sa.ajax(
          "/v1/alipay/mch/queryAllAlipaySubMerchant",
          this.p,
          function (res) {
            this.dataList = res.data.records; // 数据
            this.dataCount = res.data.total; // 分页
          }.bind(this),
          defaultCfg
        );
      }
    },
    createMchs(val) {
      var list = [];
      for (var i = 0; i < val.length; i++) {
        list[i] = val[i].id;
      }
      return list;
    },
    switchMch() {
      if (this.switchMchId == null) {
        return this.sa.error2("未选择码队");
      }

      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var params = {
        id: this.createMchs(this.multipleSelection),
        wechatMch: this.switchMchId,
        wechatMchName: this.codeMchName,
      };
      this.sa.ajax(
        "/v1/alipay/mch/skswitchMchId",
        params,
        function () {
          this.sa.ok("切换成功");
          this.cancel();
          this.f5();
          //刷新页面
        }.bind(this),
        defaultCfg
      );
    },
    cancel() {
      this.userDialogVisible = false;
      this.switchMchId == null;
      this.multipleSelection == null;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    getSwitchMchId(val) {
      let [lable, vaue] = val.split("|");
      this.switchMchId = lable;
      this.codeMchName = vaue;
    },
    zftWapPay: function (data, share) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            id: data.id,
            amount: value,
            sType: share
          };
          var defaultCfg = {
            type: "get",
          };
          self.sa.ajax(
            "/v1/alipay/config/zftPay",
            p1,
            function (res) {
              self.sa.showImageWithText(
                this.sa.cfg.api_url +
                "/v1/index/enQrcode?url=" +
                encodeURIComponent(res.data),
                "600px",
                "400px", res.data
              );
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    zftPcPay: function (data, share) {
      let self = this;
      this.sa.prompt(
        "输入创建订单的金额",
        function (value) {
          var p1 = {
            id: data.id,
            amount: value,
            sType: share
          };
          var defaultCfg = {
            type: "get",
          };
          self.sa.ajax(
            "/v1/alipay/config/zftPcPay",
            p1,
            function (res) {
              self.sa.showImageWithText(
                this.sa.cfg.api_url +
                "/v1/index/enQrcode?url=" +
                encodeURIComponent(res.data),
                "600px",
                "400px", res.data
              );
            }.bind(self),
            defaultCfg
          );
        },
        0
      );
    },
    getMchCode: function () {
      var defaultCfg = {
        type: "get", //是否是请求体请求
        sleep: 50,
        msg: null,
      };
      this.sa.ajax(
        "/v2/admin/queryMerchantNickName/3",
        function (res) {
          this.selectList = res.data; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    showUserInfoPageB(data) {
      this.multipleSelection[0] = data;
      // 设置
      if (data == null) {
        return this.sa.error2("请选择要切换的商户");
      }
      this.userDialogVisible = true;
    },
    showUserInfoPage() {
      // 设置
      if (this.multipleSelection[0] == null) {
        return this.sa.error2("请选择要切换的商户");
      }
      this.userDialogVisible = true;
    },
    del: function (row) {
      var defaultCfg = {
        type: "delete",
      };
      this.sa.ajax(
        "/v1/alipay/mch/delete/" + row.id,
        function () {
          this.sa.ok("删除成功");
          this.f5();
        }.bind(this),
        defaultCfg
      );
    },
    // 查看
    yesterDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.subMerchantId, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountYesterday",
        p2,
        function (res) {
          data.zrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshRate: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.subMerchantId, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/dataAnalysisBySubMchIdToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrcgl = res.data + "%";
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    toDayRefreshFee: function (data) {
      //获取金额
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      var p2 = {
        wechatMch: data.subMerchantId, //是否是请求体请求
      };
      this.sa.ajax(
        "/v1/dataAnalysis/realtimeAmountToDay",
        p2,
        function (res) {
          res.data = parseFloat(res.data).toFixed(2);
          data.jrsr = "￥" + res.data;
          this.$set(this.dataList, data.index, data);
          this.conditionsKey++;
        }.bind(this),
        defaultCfg
      );
    },
    /** 展示用户列表页面 **/
    add: function () {
      this.$refs["add-alipay-code-update"].open(0);
    },
    update: function (data) {
      this.$refs["add-alipay-code-update"].open(data);
    },
    setStatus: function (data) {
      this.sa.confirm(
        "是否要修改收款商户状态",
        function () {
          var defaultCfg = {
            type: "get", //是否是请求体请求
          };
          var params = data;
          this.sa.ajax(
            "/v1/alipay/mch/setStatus/" + data.id,
            function () {
              this.sa.ok("修改成功");
              params.status = params.status == 1 ? 0 : 1;
            }.bind(this), defaultCfg
          );
        }.bind(this)
      );
    },
    remarksFormat(row) {
      if (row.remarks == "" || row.remarks == null) {
        return "无备注";
      } else {
        return row.remarks;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引放进row
      row.index = rowIndex;
    },
    loadmore() {
      // this.p.page++;
      // this.getMchCode();
    },
    getZft: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      };
      var p = {
        page: 0,
        type: [1],
        payType: [0],
        limit: 30,
      };
      this.sa.ajax(
        "/v1/alipay/config/query", p,
        function (res) {
          this.zftlist = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
  },
  created: function () {
    this.f5();
    this.getMchCode();
    this.getZft();
  },
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        SELECTWRAP_DOM.addEventListener("scroll", function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
};
</script>

<style>
</style>
