<style scoped>
.c-panel {
  margin: 0px;
  padding: 0px 20px;
}
</style>

<template>
  <el-dialog v-if="m" :title="this.id == 0 ? '数据增加' : '数据修改'" :visible.sync="isShow" width="650px" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog" :show-close="false">
    <div class="vue-box">
      <div class="c-panel">
        <br />
        <el-form size="small" :inline="true" label-width="150px">
          <el-form-item label="商户名称:">
            <el-input v-model="m.mchName" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="二级商户号:">
            <el-input v-model="m.subMerchantId" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="服务商:" placeholder="请选择">
            <el-select v-model="m.appId" @click.native="getZft()">
              <el-option v-for="item in zftlist" :key="item.appid" :label="item.mchName" :value="item.appid" />
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="限额:">
            <el-input v-model="m.limits" style="width: 350px"></el-input>
          </el-form-item>
          <br />
          <el-form-item label="是否启用:">
            <el-select v-model="m.status">
              <el-option label="开启" :value="1"></el-option>
              <el-option label="关闭" :value="0"></el-option>
            </el-select>
          </el-form-item>
          <br />
          <el-form-item label="回调域名:">
            <el-input type="text" v-model="m.redirectUrl" style="width: 350px"></el-input>
            <br />
          </el-form-item>
          <el-form-item label="备注:">
            <el-input style="width: 350px" type="textarea" :rows="7" v-model="m.remarks">
            </el-input>
          </el-form-item>
          <br />
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="(isShow = false), claerData()">取 消</el-button>
      <el-button size="small" type="primary" @click="ok()">操 作</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      isShow: false,
      id: 0,
      zftlist: [],
      m: {
        subMerchantId: "", // 从菜单配置文件里传递过来的参数
        limits: 5000,
        status: 0,
        remarks: "",
        appId: null,
        mchName: "",
        redirectUrl: ""
      },
      p: {
        // 查询参数
        page: 1,
        limit: 20,
      },
    };
  },
  methods: {
    // 打开
    open: function (data) {
      this.id = data.id;
      this.isShow = true;
      console.log(this.m);
      if (this.id == 0 || data == 0) {
        // 如果是添加
        this.id = 0;
      } else {
        this.m.subMerchantId = data.subMerchantId;
        this.m.mchName = data.mchName;
        this.m.limits = data.limits;
        this.m.status = data.status;
        this.m.remarks = data.remarks;
        this.m.appId = data.appId;
        this.m.redirectUrl = data.redirectUrl;
      }
    },
    // 表单验证
    submit_check: function () {
      return "ok";
    },
    getZft: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      };
      var p = {
        page: 0,
        type: [1],
        payType: [0],
        limit: 30,
      };
      this.sa.ajax(
        "/v1/alipay/config/query", p,
        function (res) {
          this.zftlist = res.data.records; // 数据
        }.bind(this),
        defaultCfg
      );
    },
    // 提交
    ok: function () {
      // 表单验证
      if (this.submit_check() != "ok") {
        return;
      }
      // 开始增加或修改
      let self = this;
      var defaultCfg = {
        isBody: true, //是否是请求体请求
      };
      if (this.id == 0) {
        // id == 0 为增加
        this.sa.ajax(
          "/v1/alipay/mch/save",
          this.m,
          function () {
            self.sa.alert("增加成功", function () {
              self.$parent.f5(); // 父视图刷新
              self.isShow = false;
              self.claerData();
            });
          },
          defaultCfg
        );
      } else {
        // id != 0 为修改
        this.sa.ajax(
          "/v1/alipay/mch/edit/" + this.id,
          this.m,
          function () {
            self.sa.alert(
              "修改成功",
              function () {
                self.$parent.f5(); // 父视图刷新
                self.isShow = false;
                self.claerData();
              },
              defaultCfg
            );
          },
          defaultCfg
        );
      }
    },
    claerData() {
      this.m.subMerchantId = "";
      this.m.mchName = "";
      this.m.limits = "";
      this.m.status = 0;
      this.m.remarks = "";
      this.m.appId = null;
      this.m.redirectUrl = "";
    },
  },
  created: function () {
    this.getZft();
  },
};
</script>
